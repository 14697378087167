import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBuyp2d_zBdqiHXPsljw8CWVm7gnwudw_k",
  authDomain: "your-turn-af437.firebaseapp.com",
  databaseURL: "https://your-turn-af437-default-rtdb.firebaseio.com/",
  projectId: "your-turn-af437",
  storageBucket: "your-turn-af437.appspot.com",
  messagingSenderId: "336508069012",
  appId: "1:336508069012:web:acaa6d506330f17f2c4081"
};
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: 'your-turn-b96fd.firebaseapp.com',
//   databaseURL: 'https://your-turn-b96fd.firebaseio.com',
//   projectId: 'your-turn-b96fd',
//   storageBucket: 'your-turn-b96fd.appspot.com',
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();

export {
  auth
};
